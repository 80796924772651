<template>
  <div>
    <base-page title="Ussd suppliers" :breadcrumbs="breadcrumbs">
      <template #content>
        <supplier-list />
      </template>
    </base-page>
  </div>
</template>

<script>
export default {
  components: {
    'supplier-list': () => import('@/views/ussd/SupplierList.vue'),
  },

  data() {
    return {
      breadcrumbs: [{ text: 'Ussd suppliers', disabled: true }],
      dialog: false,
    }
  },

  methods: {
    close() {
      this.dialog = false
    },
  },
}
</script>
